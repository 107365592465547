import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

function initSwiper() {
  const programsCarousels = document.querySelectorAll(
    '.testimonials-block__carousel'
  );

  if (!programsCarousels) {
    return;
  }

  programsCarousels.forEach(function (programsCarousel) {
    new Swiper(programsCarousel, {
      draggable: true,
      slidesPerView: '1',
      spaceBetween: 20,
      pagination: {
        el: programsCarousel
          .closest('.testimonials-block__carousel')
          .querySelector('.swiper-pagination'),
        clickable: true,
        type: 'fraction',
      },
      navigation: {
        nextEl: programsCarousel
          .closest('.testimonials-block__carousel')
          .querySelector('.swiper-button-next'),
        prevEl: programsCarousel
          .closest('.testimonials-block__carousel')
          .querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        600: {
          slidesPerView: '1',
        },
        769: {
          slidesPerView: '2',
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: '3',
          spaceBetween: 60,
        },
      },
    });
  });
}

domReady(() => {
  initSwiper();
});

document.addEventListener('facetwp-loaded', function () {
  initSwiper();
});
